<template>
  <publisher-profiles-table
  v-if="publisher_profiles"
    :headers="headers"
    :publisher_profiles="publisher_profiles"
    class="mt-3"
  />
</template>

<script>
import PublisherProfilesTable from "../../components/Tables/PublisherProfilesTables.vue";

export default {
  data: () => ({
    headers: [
      {
        text: "Name",
        align: "start",
        sortable: false,
        value: "displayName",
        width: "50px",
      },
      {
        text: "Domains",
        align: "start",
        sortable: false,
        value: "domains",
        width: "50px",
      },
    ],
  }),
  async mounted() {

    try {
      this.$store.commit("makingApiRequest", true);
      await this.$store.dispatch("dsp/getDspPublisherProfiles");
      this.$store.commit("makingApiRequest", false);
    } catch (error) {
      this.error = error
    }
    
  },

  components: {
    PublisherProfilesTable,
  },

  computed: {
    publisher_profiles() {
      return this.$store.state.dsp.publisher_profiles;
    },
  },
  methods: {},
};
</script>
