<template>
  <v-card>
    <v-card-text>
      <h3>Publisher Profiles</h3>
    </v-card-text>
    <v-data-table
      :headers="headers"
      :items="publisher_profiles"
    >
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: {
    publisher_profiles: {
      type: Array,
    },
    headers:{
      type: Array,
    }
  },
};
</script>

<style>
</style>